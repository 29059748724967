export const AppVersion = process.env.REACT_APP_VERSION;

// keycodes
export const KEYCODE_ESCAPE = 27;
export const KEYCODE_DOWN_ARROW = 40;
export const KEYCODE_UP_ARROW = 38;
export const KEYCODE_TAB = 9;
export const KEYCODE_DEL = 8;
export const KEYCODE_ENTER = 13;

export const address_url = "/api/postal/v1/";
export const session_url = "/api/core/v2/session";
export const countries_url = "/data/countries";

export const document_variant_check_url = "/api/core/v2/document_upload/check/";
export const email_validation_url = "/api/emailverification/v1/verification/";
export const default_document_upload_api_version = "/v2";
export const document_upload_url =
  "/api/core{documentUploadVersion}/document_upload/upload";
export const submit_application_url = "/api/core/v2/loan/application";
export const kremo_url = "/api/consumercreditcheck/v2/budgetcalculations";
// various
export const INPUT_PULLDOWN_RESET_IDX = -1;
export const maxFileSize = 15 * 1024 * 1024; // 15MB
export const allowFileTypes = ["image/jpeg", "image/png", "application/pdf"];
export const max_value = 99999;

export const optimize = {
  cookieName: "_gaexp",
};

export const loan_default_values = {
  default_loan_value: 20000,
  term: 60,
  term_idx: 6,
  max_interest_rate: 10.95,
  max_loan_value: 250000,
  min_interest_rate: 5.95,
  min_loan_value: 1000,
  min_rate_value: 20,
  max_rate_value: 5000,
};

export const loan_income_values_app = {
  max_income: 99999,
  min_income: 2500,
  plausable_income: 30000,
};

export const loan_income_values_partner = {
  max_income: 99999,
  min_income: 1,
  plausable_income: 30000,
};

export const terms = [12, 18, 24, 30, 36, 48, 60, 72, 84];

export const income_frequency_extra = [12, 13, 14];
export const income_frequency_main = [11, 12, 13, 14];

export const list_income_frequency = [
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
];

export const list_extra_income_frequency = [
  {
    value: 12,
    label: "12",
  },
  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
];

export const AREA_CODE_LIST = [
  { value: "0041", label: "+41" },
  { value: "0033", label: "+33" },
  { value: "0039", label: "+39" },
  { value: "00423", label: "+423" },
  { value: "0043", label: "+43" },
  { value: "0049", label: "+49" },
];

const STEP_ROUTENAME_PREFIX = "step-";
export const ROUTENAME_STEP_1 = STEP_ROUTENAME_PREFIX + 1;
export const ROUTENAME_STEP_3 = STEP_ROUTENAME_PREFIX + 3;
export const ROUTENAME_STEP_4 = STEP_ROUTENAME_PREFIX + 4;
export const ROUTENAME_STEP_5 = STEP_ROUTENAME_PREFIX + 5;
export const ROUTENAME_STEP_6 = STEP_ROUTENAME_PREFIX + 6;
export const ROUTENAME_STEP_7 = STEP_ROUTENAME_PREFIX + 7;
export const ROUTENAME_STEP_9 = STEP_ROUTENAME_PREFIX + 9;
export const ROUTENAME_STEP_10 = STEP_ROUTENAME_PREFIX + 10;
export const ROUTENAME_STEP_11 = STEP_ROUTENAME_PREFIX + 11;
export const ROUTENAME_STEP_12 = STEP_ROUTENAME_PREFIX + 12;
export const ROUTENAME_STEP_13 = STEP_ROUTENAME_PREFIX + 13;
export const ROUTENAME_STEP_13_2 = STEP_ROUTENAME_PREFIX + 13 + "_2";
export const ROUTENAME_STEP_13_3 = STEP_ROUTENAME_PREFIX + 13 + "_3";
export const ROUTENAME_STEP_14 = STEP_ROUTENAME_PREFIX + 14;
export const ROUTENAME_EMAIL_CONFIRMATION = "email-confirmation";
export const ROUTENAME_DOC_U_S = "doc_u_s";
export const ROUTENAME_DOC_U_S_CONFIRMATION = "doc_u_s_confirmation";

export const ROUTENAMES = [
  ROUTENAME_STEP_1,
  ROUTENAME_STEP_3,
  ROUTENAME_STEP_4,
  ROUTENAME_STEP_5,
  ROUTENAME_STEP_6,
  ROUTENAME_STEP_7,
  ROUTENAME_STEP_9,
  ROUTENAME_STEP_10,
  ROUTENAME_STEP_11,
  ROUTENAME_STEP_12,
  ROUTENAME_STEP_13,
  ROUTENAME_STEP_13_2,
  ROUTENAME_STEP_13_3,
  ROUTENAME_STEP_14,
  ROUTENAME_EMAIL_CONFIRMATION,
  ROUTENAME_DOC_U_S,
  ROUTENAME_DOC_U_S_CONFIRMATION,
];

export const LOAN_STEP_CATEGORY = [];
LOAN_STEP_CATEGORY[ROUTENAME_STEP_1] = 0;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_3] = 1;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_4] = 1;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_5] = 1;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_6] = 1;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_7] = 1;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_9] = 1;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_10] = 2;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_11] = 2;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_12] = 2;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_13] = 2;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_13_2] = 2;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_13_3] = 2;
LOAN_STEP_CATEGORY[ROUTENAME_STEP_14] = 3;

export const SEARCHPARAM_LOAN_AMOUNT = "amount";
export const SEARCHPARAM_LOAN_TERM = "term";

export const IPAD_LANDSCAPE_MAX_WIDTH_PX = 1024;

export const insurance_limits = {
  ppi_max_rate_for_calculation: 2500,
  ppiplus_additional_rate_value: 500,
};

export const ExpensesHousingCosts = {
  min: 250,
  max: 50000,
  plausibility: 9999,
};

export const ExpensesFoodCosts = {
  default: 200,
  min: 1,
  max: 99999,
  plausibility: 9999,
};

export const ExpensesWorkingCosts = {
  max: 99999,
  plausibility: 9999,
};

export const ExpensesInsuranceCosts = {
  max: 99999,
  plausibility: 100,
};

export const ExpensesAdditionalCosts = {
  max: 99999,
  plausibility: 9999,
};

export const ExpensesAlimonyCosts = {
  max: 99999,
  min: 1,
  plausibility: 9999,
};

export const ExpensesMonthlyChildCareCosts = {
  max: 99999,
  min: 1,
  plausibility: 9999,
};

export const ExpensesOtherDebtsCosts = {
  max: 99999,
  min: 1,
  plausibility: 9999,
};
