import cn from "classnames";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";

import {
  KEY_PPI,
  KEY_PPI_PLUS,
  KEY_PPI_SE,
  getInsuranceByKey,
} from "../../../data/insuranceData";
import SVGIcon from "../Styleguide-Nexus";
import "./HeaderSummaryBox.css";

class HeaderSummaryBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsOpenOnMobile: false,
      onMobile: document.getElementsByTagName("body")[0].offsetWidth < 770, //width corresponds to the one in css
    };

    this.summaryBoxRef = React.createRef();
    this.summaryBoxOffsetTop = null;
    this.appRootSectionElement = null;

    this.setOnMobile = this.setOnMobile.bind(this);
  }

  componentDidMount() {
    if (document !== undefined) {
      this.appRootSectionElement = document
        .getElementById("app-root")
        .getElementsByTagName("section")[0];
      const headerHeight =
        document.getElementsByTagName("header")[0].offsetHeight;
      this.summaryBoxOffsetTop =
        headerHeight - this.summaryBoxRef.current.offsetHeight;
      this.summaryBoxRef.current.onclick = (e) => this.onToggleClick(e);

      // handle sticky only for mobile devices
      if (this.state.onMobile) {
        window.onscroll = () => this.onScroll();
        this.summaryBoxRef.current.onclick = (e) =>
          this.onToggleClick(e, false);
        this.appRootSectionElement.onclick = (e) => this.onToggleClick(e, true);
      }
    }

    window.addEventListener("resize", this.setOnMobile);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setOnMobile);
  }

  setOnMobile() {
    let isOnMobile = document.getElementsByTagName("body")[0].offsetWidth < 770;
    this.setState({ onMobile: isOnMobile });

    if (!isOnMobile && this.state.detailsOpenOnMobile) {
      this.appRootSectionElement.classList.remove("app-content-opaque");
    } else if (isOnMobile && this.state.detailsOpenOnMobile) {
      this.appRootSectionElement.classList.add("app-content-opaque");
    }
  }

  isMobileSummaryBoxExpandable = () => {
    // mobile dropdown active after step-2
    // (first item (loan amount) is displayed in the header already, so no need for dropdown at this point)
    return this.state.onMobile && this.props.viewStore.step_validated >= 1;
  };

  onScroll = () => {
    if (this.summaryBoxRef && this.summaryBoxRef.current) {
      if (window.pageYOffset > this.summaryBoxOffsetTop) {
        this.summaryBoxRef.current.classList.add("summary-box-sticky");
      } else {
        this.summaryBoxRef.current.classList.remove("summary-box-sticky");
      }
    }
  };

  onToggleClick = (e, appRootClick) => {
    if (!appRootClick) {
      e && e.preventDefault();
      if (this.state.onMobile && this.isMobileSummaryBoxExpandable()) {
        this.setState(
          { detailsOpenOnMobile: !this.state.detailsOpenOnMobile },
          () => {
            this.appRootSectionElement && this.state.detailsOpenOnMobile
              ? this.appRootSectionElement.classList.add("app-content-opaque")
              : this.appRootSectionElement.classList.remove(
                  "app-content-opaque"
                );
          }
        );
      }
    } else if (this.state.detailsOpenOnMobile) {
      e && e.preventDefault();
      if (this.state.onMobile && this.isMobileSummaryBoxExpandable()) {
        this.setState({ detailsOpenOnMobile: !this.state.detailsOpenOnMobile });
        this.appRootSectionElement.classList.remove("app-content-opaque");
      }
    }
  };

  render() {
    const { t, loanStore, viewStore, userStore } = this.props;

    const summaryBoxPageContainerClasses = cn({
      "l-page-container": true,
      "l-page-container--invert": true,
      "summary-box-page-container-hide":
        !loanStore.loanAmountValue ||
        viewStore.step_validated < 1 ||
        viewStore.step_validated >= 12,
    });
    const summaryBoxContainerClasses = cn({
      "summary-box-container": true,
      "summary-box-container-trim-right": !(
        loanStore.insuranceValue > 0 && viewStore.step_validated >= 9
      ),
    });
    const summaryBoxListClasses = cn({
      "summary-box-header__item-amount-mobile":
        this.state.onMobile && loanStore.getMonthlyRate() <= 0,
      "summary-box-list":
        !this.state.onMobile || loanStore.getMonthlyRate() > 0,
      "summary-box-list--open":
        (!this.state.onMobile || loanStore.getMonthlyRate() > 0) &&
        this.state.onMobile &&
        this.state.detailsOpenOnMobile,
    });

    const summaryBoxListItemClasses = cn({
      "summary-box-list-item": true,
      "summary-box-list-item--visible":
        this.state.onMobile && this.state.detailsOpenOnMobile,
    });

    let insurancename = "";
    if (
      loanStore.insuranceTypeId === getInsuranceByKey(KEY_PPI).id &&
      userStore.incomeSourceId !== 9
    ) {
      insurancename = t("global:header.summaryBox.insuranceItemLabel");
    } else if (
      loanStore.insuranceTypeId === getInsuranceByKey(KEY_PPI_PLUS).id
    ) {
      insurancename = t("global:header.summaryBox.insuranceItemLabelPlus");
    } else if (
      loanStore.insuranceTypeId === getInsuranceByKey(KEY_PPI_SE).id &&
      userStore.incomeSourceId === 9
    ) {
      insurancename = t(
        "global:header.summaryBox.insuranceItemLabelSelfEmployed"
      );
    }

    return (
      <div ref={this.summaryBoxRef} className={summaryBoxPageContainerClasses}>
        <div className={!this.state.onMobile ? "l-page-inner" : undefined}>
          <div
            className={
              !this.state.onMobile ? "g-container g-layout--header" : undefined
            }
          >
            <div className={!this.state.onMobile ? "g-cell" : undefined}>
              <div className={summaryBoxContainerClasses}>
                <div className="summary-box-header">
                  <div className="summary-box-header-label">
                    <div className="summary-box-toggle">
                      {this.isMobileSummaryBoxExpandable() && (
                        <div className="m-toggle-content__toggle">
                          {this.state.detailsOpenOnMobile ? (
                            <SVGIcon type="base" icon="keyboard_arrow_left" />
                          ) : (
                            <SVGIcon type="base" icon="keyboard_arrow_right" />
                          )}
                        </div>
                      )}
                    </div>
                    <span>{t("global:header.summaryBox.label")}</span>
                  </div>
                  <div className="summary-box-header__item-amount-mobile">
                    <span>
                      {t("global:labels.currency_CHF")}{" "}
                      {viewStore.prettifyNumber(loanStore.loanAmountValue)}
                    </span>
                  </div>
                </div>
                {this.state.detailsOpenOnMobile &&
                  (!this.state.onMobile || loanStore.getMonthlyRate() > 0) && (
                    <hr className="summary-box-separator" />
                  )}

                <ul className={summaryBoxListClasses}>
                  {
                    // displayed in summary box only on desktop
                    loanStore.loanAmountValue &&
                      viewStore.step_validated >= 1 &&
                      !this.state.onMobile && (
                        <li className={summaryBoxListItemClasses}>
                          <span className={"summary-box-list-item-primary"}>
                            {t("global:labels.currency_CHF")}
                          </span>
                          <span className={"summary-box-list-item-primary"}>
                            {viewStore.prettifyNumber(
                              loanStore.loanAmountValue
                            )}
                          </span>
                        </li>
                      )
                  }

                  {loanStore.loanTerm &&
                    loanStore.getMonthlyRate() &&
                    loanStore.getMonthlyRate() > 0 && (
                      <li className={summaryBoxListItemClasses}>
                        <span className={"summary-box-list-item-secondary"}>
                          {t("global:header.summaryBox.loanTermItemLabel")}
                        </span>
                        <span>
                          {loanStore.loanTerm}{" "}
                          {t(
                            "global:header.summaryBox.loanTermItemValueSuffix"
                          )}
                        </span>
                      </li>
                    )}

                  {loanStore.loanTerm &&
                    !loanStore.getMonthlyRate() &&
                    viewStore.rounding5cents(
                      loanStore.calculateFinancingRate(
                        loanStore.loanTerm,
                        "MAX"
                      )
                    ) > 0 && (
                      <li className={summaryBoxListItemClasses}>
                        <span className={"summary-box-list-item-secondary"}>
                          {t("global:header.summaryBox.loanTermItemLabel")}
                        </span>
                        <span>
                          {loanStore.loanTerm}{" "}
                          {t(
                            "global:header.summaryBox.loanTermItemValueSuffix"
                          )}
                        </span>
                      </li>
                    )}

                  {loanStore.loanTerm &&
                    loanStore.getMonthlyRate() &&
                    loanStore.getMonthlyRate() > 0 && (
                      <li className={summaryBoxListItemClasses}>
                        <span className={"summary-box-list-item-secondary"}>
                          {t(
                            "global:header.summaryBox.monthlyInstallmentItemLabel"
                          )}
                        </span>
                        <span className="summary-box-media-br">
                          <Trans i18nKey="global:header.summaryBox.monthlyInstallmentItemValueSuffix">
                            #
                            {viewStore.prettifyNumber(
                              viewStore.paddingFraction(
                                loanStore.getMonthlyRate("MIN")
                              )
                            )}
                            {viewStore.prettifyNumber(
                              viewStore.paddingFraction(
                                loanStore.getMonthlyRate("MAX")
                              )
                            )}
                            #
                          </Trans>
                        </span>
                      </li>
                    )}

                  {loanStore.loanTerm &&
                    !loanStore.getMonthlyRate() &&
                    viewStore.rounding5cents(
                      loanStore.calculateFinancingRate(
                        loanStore.loanTerm,
                        "MAX"
                      )
                    ) > 0 && (
                      <li className={summaryBoxListItemClasses}>
                        <span className={"summary-box-list-item-secondary"}>
                          {t(
                            "global:header.summaryBox.monthlyInstallmentItemLabel"
                          )}
                        </span>
                        <span>
                          <Trans i18nKey="global:header.summaryBox.monthlyInstallmentItemValueSuffix">
                            #
                            {viewStore.prettifyNumber(
                              viewStore.rounding5cents(
                                loanStore.calculateFinancingRate(
                                  loanStore.loanTerm,
                                  "MIN"
                                )
                              )
                            )}
                            {viewStore.prettifyNumber(
                              viewStore.rounding5cents(
                                loanStore.calculateFinancingRate(
                                  loanStore.loanTerm,
                                  "MAX"
                                )
                              )
                            )}
                            #
                          </Trans>
                        </span>
                      </li>
                    )}

                  {loanStore.insuranceValue !== null &&
                    loanStore.insuranceValue > 0 &&
                    loanStore.insuranceTypeId > -1 &&
                    !userStore.step9skipped && (
                      <li className={summaryBoxListItemClasses}>
                        <span className={"summary-box-list-item-secondary"}>
                          {insurancename}
                        </span>
                        <span>
                          <Trans i18nKey="global:header.summaryBox.insuranceItemLabelValueWrapper">
                            #
                            {viewStore.prettifyNumber(
                              viewStore.paddingFraction(
                                loanStore.insuranceValue
                              )
                            )}
                            #
                          </Trans>
                        </span>
                      </li>
                    )}

                  {loanStore.insuranceValue === 0 &&
                    (loanStore.insuranceTypeId === 0 ||
                      loanStore.insuranceTypeId === 9) &&
                    !userStore.step9skipped && (
                      <li className={summaryBoxListItemClasses}>
                        <span className={"summary-box-list-item-secondary"}>
                          {t("global:header:summaryBox:noInsuranceItemLabel")}
                        </span>
                        <span>
                          <Trans i18nKey="global:header.summaryBox.insuranceItemLabelValueWrapper">
                            #
                            {viewStore.prettifyNumber(
                              viewStore.paddingFraction(0.0)
                            )}
                            #
                          </Trans>
                        </span>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HeaderSummaryBox.propTypes = {
  loanStore: PropTypes.object,
  t: PropTypes.func,
  viewStore: PropTypes.object,
  userStore: PropTypes.object,
};

export default withTranslation()(
  inject(({ stores }) => ({
    loanStore: stores.loan,
    viewStore: stores.view,
    userStore: stores.user,
  }))(observer(HeaderSummaryBox))
);
